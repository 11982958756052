import {
  Message,
  ViewModeMessageType,
  ViewMode,
  NavigationMessageType,
  DataRefreshTargetEntity,
  DataRefreshMessageType,
} from './types';

export const ViewModeMessage = {
  create: (value: ViewMode): Message<ViewModeMessageType> => ({
    type: 'viewMode',
    payload: {
      value,
    },
  }),
};

export const NavigationMessage = {
  create: (route: string): Message<NavigationMessageType> => ({
    type: 'navigation',
    payload: {
      route,
    },
  }),
};

export const DataRefreshMessage = {
  create: (
    entity: DataRefreshTargetEntity,
  ): Message<DataRefreshMessageType> => ({
    type: 'dataRefresh',
    payload: {
      entity,
    },
  }),
};
