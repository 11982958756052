import {
  createContext,
  FC,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAuth0, User } from '@auth0/auth0-react';
import { Box, Spinner, Text } from 'grommet';
import styled from 'styled-components';
import { Unauthorized } from '@/lib/components/app/Auth/Unauthorized';
import { useShellQuery } from '@/lib/contexts/PostMessageContext/hooks';
import {
  AuthMessageType,
  Message,
} from '@/lib/contexts/PostMessageContext/types';

const Overlay = styled(Box)((_props) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}));

type AppSession = {
  user: User;
  token: string;
} | null;

type AuthProps = {
  children?: ReactNode;
};

type AuthContextType = {
  loading: boolean;
  getSession: () => AppSession;
};

const AuthContext = createContext<AuthContextType | null>(null);

const useAuthContextValue = (): [
  AuthContextType,
  boolean,
  MutableRefObject<AppSession | null>,
] => {
  const [ready, setReady] = useState(false);
  const session = useRef<AppSession>(null);
  const { getAccessTokenSilently, user, isAuthenticated, isLoading } =
    useAuth0();
  const query = useShellQuery();

  const auth0Authenticate = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      session.current = {
        token,
        user: {},
      };
    } catch (e) {
      console.log(e);
    } finally {
      setReady(true);
    }
  }, [getAccessTokenSilently]);

  const shellAuthenticate = useCallback((message: Message<AuthMessageType>) => {
    if (message.payload.token) {
      session.current = message.payload;
    } else {
      session.current = null;
    }

    setReady(true);
  }, []);

  const loadSessionInfo = useCallback(async () => {
    const message: Message<AuthMessageType> = {
      type: 'auth',
      payload: {
        action: 'getSession',
        user: null,
        token: null,
      },
    };

    try {
      const res = await query(message);

      // If there's no response, try to authenticate with the Auth0 SDK
      if (!res || !(res as Message<AuthMessageType>)?.payload?.token) {
        void auth0Authenticate();
        return;
      }

      shellAuthenticate(res as Message<AuthMessageType>);
    } catch (_) {
      void auth0Authenticate();
    }
  }, [query, auth0Authenticate, shellAuthenticate]);

  const contextValue = useMemo(
    (): AuthContextType => ({
      loading: !ready,
      getSession: () => session.current,
    }),
    [session, ready],
  );

  useEffect(() => {
    setTimeout(() => {
      void loadSessionInfo();
    }, 1000);
  }, [loadSessionInfo]);

  // This one takes care of updating the session info with the user if available
  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated || !user || !session.current?.token) {
      return;
    }

    session.current = {
      ...session.current,
      user,
    };
  }, [user, isAuthenticated, isLoading]);

  return [contextValue, !ready, session];
};

export const Auth: FC<AuthProps> = ({ children }) => {
  const [contextValue, loading, session] = useAuthContextValue();

  if (process.env.NEXT_PUBLIC_AUTH_DISABLED) {
    console.log('[rdz.Auth] Disabled');
    return (
      <AuthContext.Provider
        value={{
          loading: false,
          getSession: () => null,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }

  if (loading) {
    return (
      <Overlay justify="center" align="center">
        <Text>Loading...</Text>
        <Spinner />
      </Overlay>
    );
  }

  if (!session.current?.token || !session.current?.user) {
    return <Unauthorized />;
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextType => {
  const ctx = useContext(AuthContext);
  if (!ctx) {
    throw new Error('No AuthContextProvider found');
  }

  return ctx;
};
