import { FC } from 'react';
import { Box, BoxExtendedProps } from 'grommet';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  // Using stylesheet notation but must replace with object notation
  @keyframes blink {
    0% {
      background-color: rgb(196, 196, 196, 0.3);
    }
    50% {
      background-color: rgb(196, 196, 196, 1);
    }
    100% {
      background-color: rgb(196, 196, 196, 0.3);
    }
  }

  animation: blink 1500ms infinite;
`;

type SkeletonBoxProps = BoxExtendedProps & {
  visible?: boolean;
};

export const SkeletonBox: FC<SkeletonBoxProps> = (boxProps) => {
  return <StyledBox {...boxProps} />;
};
