import { FC } from 'react';
import { Roboto } from 'next/font/google';

const robotoFont = Roboto({
  weight: ['300', '400', '500', '700', '900'],
  subsets: ['latin'],
});

export const Typography: FC = () => {
  return (
    <style jsx global>{`
      html {
        font-family: ${robotoFont.style.fontFamily};
      }
    `}</style>
  );
};
