import styled from 'styled-components';
import { Box } from 'grommet';

export const TabContainer = styled(Box)`
  padding: 4px 10px;
  color: black;
`;

export const TabsContainer = styled(Box).attrs({
  direction: 'row',
})`
  // TODO: replace for button scrolling
  overflow-x: auto;

  // Styling of the border has been kept to the a tag to make it
  // play nicer with the focus outline (previously the styling was
  // aimed to the child of 'a', but when focused, the outline clipped
  // as there was a white border on top.
  > a {
    text-decoration: none;
    padding-top: 4px;

    :focus-visible {
      outline-color: ${(p) => p.theme.global.colors.secondary};
    }

    &[aria-selected='true'] {
      padding-top: 0;

      &:before {
        content: '';
        background-color: ${(p) => p.theme.global.colors.brand};
        display: block;
        width: 100%;
        height: 4px;
      }
    }
  }

  ${TabContainer} {
    color: black;
    padding: 4px 10px;
  }

  ${TabContainer}[aria-selected='true'] {
    font-weight: 900;
    border-top-color: ${(p) => p.theme.global.colors.brand};
    background-color: ${(p) => p.theme.global.colors.background};

    &:after {
      content: '';
      width: 100%;
      height: 4px;
    }
  }
`;
