import { FC } from 'react';
import { Box, Button, ButtonProps, ThemeContext, ThemeType } from 'grommet';
import { css } from 'styled-components';

type RaisedButtonProps = ButtonProps;

const extendedTheme: ThemeType = {
  button: {
    default: {
      border: {
        width: '0',
      },
      // @ts-ignore TODO: Fix this typing issue properly. Did this to save time & allow typescript compilation
      extend: css<ButtonProps>`
        text-transform: uppercase;
        flex: 1;

        ${({ icon }) => {
          if (!icon) {
            return null;
          }

          return css`
            div {
              display: flex;
              flex-direction: column;

              & > :first-child {
                margin-bottom: 1rem;
              }
            }
          `;
        }}
      `,
    },
  },
};

export const RaisedButton: FC<RaisedButtonProps> = (props) => {
  return (
    <ThemeContext.Extend value={extendedTheme}>
      <Box
        elevation="medium"
        round="10px"
        hoverIndicator={{ elevation: 'large' }}
      >
        <Button {...props} />
      </Box>
    </ThemeContext.Extend>
  );
};
