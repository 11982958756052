import { useMemo } from 'react';
import { format, parse, parseISO } from 'date-fns';
import { mergeDeep } from '@apollo/client/utilities';

type FormatConfiguration = {
  /**
   * If specified, this will be used to parse the date based on this format
   * string
   */
  inputFormat?: string;

  /**
   * If specified, this will format the date following this specific format.
   *
   * Defaults to: MMM Do YYYY at hh:mm:ss a
   */
  outputFormat: string;
};

type FormatFunction = (
  value?: string | null,
  config?: Partial<FormatConfiguration>,
) => string;

type UseFormatHook = (
  value?: string | null,
  config?: Partial<FormatConfiguration>,
) => string;

const defaultConfig = (): FormatConfiguration => ({
  outputFormat: 'MMM do hh:mm:ss a',
});

export const formatDate: FormatFunction = (value, config = defaultConfig()) => {
  if (!value) {
    return '';
  }

  let parsed: Date | null;

  if (!config.inputFormat) {
    parsed = parseISO(value);
  } else {
    parsed = parse(value, config.inputFormat, new Date());
  }

  return format(parsed, config.outputFormat as string);
};

export const useFormatDate: UseFormatHook = (value, config) => {
  const formatConfig = useMemo(() => {
    if (!config) {
      return defaultConfig();
    }
    return mergeDeep(defaultConfig(), config);
  }, [config]);

  return useMemo(() => {
    return formatDate(value, formatConfig);
  }, [value, formatConfig]);
};
