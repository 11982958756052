import { useEffect, useCallback, useMemo } from 'react';
import { DataRefreshTargetEntity } from './types';
import { DataRefreshMessage } from './createMessageType';
import { usePostMessageContext } from './PostMessageContext';

export const useShellDataRefresh = (initial?: DataRefreshTargetEntity) => {
  const memoizedInitial = useMemo(() => initial, [initial]);
  const { publish } = usePostMessageContext();

  useEffect(() => {
    if (!memoizedInitial) {
      return;
    }

    publish(DataRefreshMessage.create(memoizedInitial));
  }, [memoizedInitial, publish]);

  const toggle = useCallback(
    (value: DataRefreshTargetEntity) => {
      publish(DataRefreshMessage.create(value));
    },
    [publish],
  );

  return toggle;
};
