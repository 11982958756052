import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { formatDate } from '@/lib/utils/date';
import { buildAppUrl } from '@/lib/utils/urls/buildAppUrl';

export const createCache = () => {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          assets: relayStylePagination(['assetKind', 'search', 'filter']),
          reportRows: relayStylePagination(['reportId', 'search', 'filter']),
        },
      },
      DataReportSnapshot: {
        fields: {
          label: {
            read(_, { readField }) {
              return formatDate(readField('createdAt'));
            },
          },
          url: {
            read(_, { readField }) {
              return buildAppUrl('/data-reports/snapshots/{id}/', {
                id: readField('id'),
              });
            },
          },
        },
      },
    },
  });
};
