import { ThemeType } from 'grommet';
import { ButtonKindType, Colors } from 'grommet/themes/base';
import { css, FlattenSimpleInterpolation } from 'styled-components';

const colors: Colors = {
  brand: '#2B404E',
  secondary: '#F6C560',
  placeholder: '#737373',
  text: {
    light: '#2B404E',
    dark: 'white',
  },
  secondaryText: '#484848',
  background: '#ededed',

  dashboardGreen: '#1DB954',
  dashboardRed: '#DA1E28',
  dashboardGrey: '#f4f4f4',

  dashboardCellHover: '#525252',
};

const defaultButton: ButtonKindType = {
  border: {
    color: 'rgba(43,64,78,0.5)',
    width: '1px',
    radius: '8px',
  },
  padding: {
    vertical: '6px',
    horizontal: '26px',
  },
  font: {
    weight: '600',
  },
  extend: css`
    text-transform: uppercase;
  `,
};

const primaryButton: ButtonKindType = {
  ...defaultButton,
  background: {
    color: 'brand',
  },
  color: 'white',
};

const secondaryButton: ButtonKindType = {
  ...defaultButton,
  background: {
    color: 'secondary',
  },
};

const disabledButton: ButtonKindType = {
  ...defaultButton,
  background: {
    color: 'transparent',
  },
};

export const theme: ThemeType = {
  calendar: {
    heading: {
      level: '5',
    },
  },
  button: {
    default: defaultButton,
    primary: primaryButton,
    secondary: secondaryButton,
    disabled: disabledButton,
  },
  checkBox: {
    size: '15px',
    check: {
      radius: '0',
    },
    toggle: {
      radius: '0',
    },
  },
  radioButton: {
    size: '18px',
  },
  layer: {
    overlay: {
      background: 'rgba(255, 255, 255, 0.4)',
    },
  },
  global: {
    colors,
    focus: {
      border: {
        color: 'secondary',
      },
    },
    edgeSize: {
      large: '37px',
    },
    input: {
      padding: {
        vertical: '4px',
        horizontal: '12px',
      },
      font: {
        size: '16px',
        height: '24px',
      },
      extend: css`
        border: 1px solid #737373;

        ::placeholder {
          color: #737373;
          font-weight: 400;
        }

        :focus::placeholder {
          color: #171717;
        }
      `,
    },
    control: {
      border: {
        radius: '0',
      },
      disabled: {
        opacity: '0.6',
      },
    },
  },
  text: {
    medium: {
      size: '15px',
    },
    skeleton: {
      background: {
        color: '#C4C4C4',
      },
    },
  },
  tag: {
    round: {
      size: '8px',
    },
    pad: {
      vertical: '2px',
    },
  },
  heading: {
    color: 'text',
    weight: 400,
  },
  tabs: {
    background: {
      color: '#ededed',
    },
    extend: (_) => {
      return css`
        // Target tab label

        [aria-selected='true'] > div {
          font-weight: 900;
          border-top: 4px solid ${(p) => p.theme.global.colors.brand};
        }

        [aria-selected='false'] > div {
          border-top: 4px solid white;
        }
      ` as FlattenSimpleInterpolation;
    },
  },
  tab: {
    background: {
      color: 'white',
    },
    border: {
      size: '0',
    },
    margin: '0',
    pad: {
      horizontal: '10px',
      vertical: '4px',
    },
    active: {
      background: {
        color: '#ededed',
      },
    },
  },
  table: {
    header: {
      background: 'secondary',
      border: undefined,
      pad: '13px',
      extend: ({ className }) => {
        if (className === 'primary') {
          return css``;
        }

        return css`
          background-color: #f6c560;
        `;
      },
    },
    body: {
      background: 'white',
    },
  },
};
