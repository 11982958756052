import { useEffect, useCallback, useMemo } from 'react';
import { ViewMode } from './types';
import { ViewModeMessage } from './createMessageType';
import { usePostMessageContext } from './PostMessageContext';

export const useShellViewMode = (initial?: ViewMode) => {
  const memoizedInitial = useMemo(() => initial, [initial]);
  const { publish } = usePostMessageContext();

  useEffect(() => {
    if (!memoizedInitial) {
      return;
    }

    publish(ViewModeMessage.create(memoizedInitial));
  }, [memoizedInitial, publish]);

  const toggle = useCallback(
    (mode: ViewMode) => {
      publish(ViewModeMessage.create(mode));
    },
    [publish],
  );

  return toggle;
};
