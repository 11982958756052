import { FC } from 'react';
import { useAssetKindsQuery } from '@/lib/generated/graphql';
import { NavigationTab, NavigationTabs } from '@/lib/components/NavigationTabs';
import { SkeletonBox } from '@/lib/components/skeleton';

type AssetKindTabsProps = {};

export const AssetKindTabs: FC<AssetKindTabsProps> = () => {
  const { data, loading } = useAssetKindsQuery({
    variables: {
      first: 10,
      hasSchema: true,
    },
  });

  if (loading) {
    return (
      <NavigationTabs margin={{ top: '1rem' }}>
        <SkeletonBox width="200px" height="40px" />
      </NavigationTabs>
    );
  }

  return (
    <NavigationTabs margin={{ top: '30px' }}>
      {data?.assetKinds?.edges?.map((edge, idx) => (
        <NavigationTab
          key={idx}
          label={edge?.node?.name || 'n/a'}
          href={`/assets/${edge?.node?.id}`}
        />
      ))}
    </NavigationTabs>
  );
};
