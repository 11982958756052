import { FC, ReactNode, useEffect, useState } from 'react';
import { createClient } from '@/lib/api/graphql/apollo';
import { ApolloProvider } from '@apollo/client';
import { useAuthContext } from '@/lib/components/app/Auth/Auth';

type ApolloProps = {
  children?: ReactNode;
};

export const Apollo: FC<ApolloProps> = ({ children }) => {
  const { loading, getSession } = useAuthContext();
  const [client, setClient] = useState(createClient);

  useEffect(() => {
    if (loading) {
      return;
    }

    const session = getSession();

    if (!session) {
      return;
    }

    setClient(createClient(session.token));
  }, [loading, getSession]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
