const isSentryEnabled = (): boolean => {
  let sentryEnabled = true;

  if (process.env.SENTRY_ENABLED === 'false') {
    sentryEnabled = false;
  } else if (
    process.env.SENTRY_ENABLED === 'true' ||
    process.env.NODE_ENV !== 'development'
  ) {
    sentryEnabled = true;
  }
  return sentryEnabled;
};

export const generalConfig = {
  sentry: {
    enabled: isSentryEnabled(),
  },
};

export type GeneralConfiguration = typeof generalConfig;
