import { useCallback } from 'react';
import { Message } from '@/lib/contexts/PostMessageContext/types';

type UseShellQueryHook = () => (message: Message) => Promise<Message | null>;

/**
 * This hooks pushes a message to the shell and waits for a message back.
 */
export const useShellQuery: UseShellQueryHook = () => {
  return useCallback(
    (message: Message) =>
      new Promise<Message | null>((resolve, reject) => {
        // App is not rendering on an iframe
        if (window.location === window.parent.location) {
          reject(new Error('The app is not running inside the shell'));
          return;
        }

        const request: Message = {
          // Using current datetime as an id
          id: new Date().getTime().toString(),
          ...message,
        };

        let response: Message | null = null;

        const messageListener = (e: MessageEvent) => {
          if (!(e.data satisfies Message)) {
            return;
          }

          if ((e.data as Message)?.id !== request.id) {
            return;
          }

          // Message received,
          resolve(e.data as Message);
        };

        const onTimeout = () => {
          if (response === null) {
            resolve(null);
          }

          window.removeEventListener('message', messageListener);
        };

        window.addEventListener('message', messageListener);

        window.parent.postMessage(request, document.referrer);

        // Default timeout of 2s
        setTimeout(onTimeout, 5000);
      }),
    [],
  );
};
