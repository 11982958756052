import { FC } from 'react';
import { Box, Text } from 'grommet';

export const Unauthorized: FC = () => {
  return (
    <Box justify="center" align="center" direction="column" fill>
      <Text>Please login in order to continue</Text>
    </Box>
  );
};
