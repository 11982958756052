import { FC, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Box, Text } from 'grommet';
import Link from 'next/link';
import styled from 'styled-components';
import { useBreadcrumbContext } from './context';
import { startCase } from 'lodash';
import { InvalidLinks } from './invalidLinks';
import { InactiveLinks } from './inactiveLinks';

const StyledContainer = styled(Box).attrs({
  direction: 'row',
  gap: 'small',
  margin: {
    bottom: '20px',
  },
})``;

const StyledLink = styled(Link)((_) => ({
  color: 'black',
  textDecoration: 'unset',
  textTransform: 'capitalize',
}));

type BreadcrumbEntry = {
  label: string;
  href: string;
};

export const Breadcrumbs: FC = () => {
  const { breadcrumbLabels } = useBreadcrumbContext();
  const { pathname, asPath, isReady } = useRouter();

  const entries: BreadcrumbEntry[] = useMemo(() => {
    if (!isReady) {
      return [];
    }

    const paths = pathname.split('/').filter(Boolean);
    const linkPaths = asPath.split('/').filter(Boolean);

    const items = [];
    paths.forEach((path, idx) => {
      let label = startCase(path);
      const href = '/' + linkPaths.slice(0, idx + 1).join('/');
      const isValidLink = !InvalidLinks.has(href) && !InactiveLinks.has(href);

      // Check if the breadcrumbs map contains a label for the variable
      if (path.startsWith('[')) {
        const key = path.replace(/[\[\]]/g, '');
        label = breadcrumbLabels?.[key] || label;
      }

      items.push({
        href: isValidLink ? href : '',
        label,
      });
    });

    items.unshift({
      label: 'Home',
      href: '/',
    });

    return items;
  }, [pathname, asPath, isReady, breadcrumbLabels]);

  return (
    <StyledContainer>
      {entries.map((entry, id) =>
        entry.href !== '' ? (
          <StyledLink key={id} href={entry.href}>
            <Box direction="row" gap="small">
              <Text>{entry.label}</Text>
              {id < entries.length - 1 && entries.length > 1 ? (
                <Text>/</Text>
              ) : null}
            </Box>
          </StyledLink>
        ) : (
          <Text key={id}>
            {entry.label}
            {id < entries.length - 1 && entries.length > 1 ? (
              <Text margin={{ left: '10px' }}>/</Text>
            ) : null}
          </Text>
        ),
      )}
    </StyledContainer>
  );
};
