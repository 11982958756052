import { FC } from 'react';
import { BaseLayoutProps } from '@/lib/layouts/types';
import { Breadcrumbs } from '@/lib/components/app/Breadcrumbs';
import { ContentWrapper } from '@/lib/components/app/ContentWrapper';

export const BlankLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <ContentWrapper>
      <Breadcrumbs />
      {children}
    </ContentWrapper>
  );
};
