type Params = Record<string, string | null | undefined>;

type BuildAppUrlType = (route: string, params?: Params) => string;

export const buildAppUrl: BuildAppUrlType = (route, params) => {
  let path = route;

  if (params) {
    path = path.replace(/\{(\w+)}/g, (match, key) => {
      return params?.[key] || match;
    });
  }

  return `${path}`;
};
