import styled from 'styled-components';
import { Box } from 'grommet';

export const NavigationTabContent = styled(Box)`
  background-color: ${(p) => p.theme.global.colors.background};
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
