import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';

type BreadcrumbContext = {
  breadcrumbLabels: Record<string, string>;
  setBreadcrumbLabel: (key: string, value?: string) => void;
};

const Context = createContext<BreadcrumbContext | null>(null);

export const useBreadcrumbContext = (): BreadcrumbContext => {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('Breadcrumb context not found');
  }

  return ctx;
};

type BreadcrumbsContextProviderProps = {
  children?: ReactNode;
};

export const BreadcrumbsContextProvider: FC<
  BreadcrumbsContextProviderProps
> = ({ children }) => {
  const [breadcrumbLabels, setBreadcrumbLabels] = useState<
    Record<string, string>
  >({});

  const { pathname } = useRouter();

  const setBreadcrumbLabel = useCallback(
    (key: string, value?: string) => {
      setBreadcrumbLabels((p) => {
        if (!value) {
          delete p?.[key];
          return { ...p };
        }

        return { ...p, [key]: value };
      });
    },
    [setBreadcrumbLabels],
  );

  // Reset breadcrumbs
  useEffect(() => {
    setBreadcrumbLabels({});
  }, [pathname]);

  return (
    <Context.Provider value={{ breadcrumbLabels, setBreadcrumbLabel }}>
      {children}
    </Context.Provider>
  );
};
