import { useBreadcrumbContext } from './context';
import { useEffect } from 'react';

export const useSetBreadcrumbLabel = (key: string, value?: string) => {
  const { setBreadcrumbLabel } = useBreadcrumbContext();

  useEffect(() => {
    setBreadcrumbLabel(key, value);
  }, [key, value, setBreadcrumbLabel]);
};
