import { createContext, FC, useContext, useRef, MutableRefObject } from 'react';
import {
  AssetsTableFilterValueProps,
  AssetsTableFilterContextProviderProps,
  AssetsTableFilterContextType,
  Filters,
} from './types';

const AssetsTableFilterContext =
  createContext<AssetsTableFilterContextType | null>(null);

export const AssetsTableFilterContextProvider: FC<
  AssetsTableFilterContextProviderProps
> = ({ children }) => {
  const initialFilters = useRef<Filters>(null);

  const contextValue = {
    initialFilters,
  };
  return (
    <AssetsTableFilterContext.Provider
      value={contextValue as AssetsTableFilterValueProps}
    >
      {children}
    </AssetsTableFilterContext.Provider>
  );
};

export const useAssetsTableFilterContext = (): {
  initialFilters: MutableRefObject<Filters> | null;
} => {
  const ctx = useContext(AssetsTableFilterContext);

  if (!ctx) {
    throw new Error('Could not find AssetsTableFilterContext');
  }

  return ctx;
};
