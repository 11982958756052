import { FC, ReactNode } from 'react';
import { TabsContainer } from './styled';
import { BoxProps } from 'grommet';

type NavigationTabsProps = BoxProps & {
  children?: ReactNode;
};

export const NavigationTabs: FC<NavigationTabsProps> = ({
  children,
  ...props
}) => {
  return <TabsContainer {...props}>{children}</TabsContainer>;
};
