import {
  MessageHandler,
  MessageTypes,
} from '@/lib/contexts/PostMessageContext/types';
import { useEffect, useMemo } from 'react';
import { usePostMessageContext } from '@/lib/contexts/PostMessageContext';

type UsePostMessageListenerHook = (
  id: string,
  type: MessageTypes,
  handler: MessageHandler,
) => void;

export const usePostMessageListener: UsePostMessageListenerHook = (
  id,
  type,
  handler,
) => {
  const memoizedHandler = useMemo(() => handler, [handler]);
  const { addMessageListener, removeMessageListener } = usePostMessageContext();

  useEffect(() => {
    addMessageListener(id, type, memoizedHandler);

    return () => {
      // Remove memoized handler from listener
      removeMessageListener(id, type);
    };
  }, [id, type, addMessageListener, removeMessageListener, memoizedHandler]);
};
