import { FC, useEffect, useState } from 'react';
import { Text } from 'grommet';
import Link from 'next/link';
import { UrlObject } from 'url';
import { TabContainer } from './styled';
import { useRouter } from 'next/router';

type NavigationTabProps = {
  label: string;
  href: string | UrlObject;
};

const useIsActive = (url: string | UrlObject): boolean => {
  const { asPath: route } = useRouter();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (typeof url === 'string') {
      setActive(route === url);
      return;
    }

    setActive(route === url.pathname);
  }, [route, url]);

  return active;
};

export const NavigationTab: FC<NavigationTabProps> = ({ label, href }) => {
  const isActive = useIsActive(href);

  return (
    <Link href={href} aria-selected={isActive}>
      <TabContainer aria-selected={isActive}>
        <Text>{label}</Text>
      </TabContainer>
    </Link>
  );
};
