import { FC, ReactNode } from 'react';
import { Box } from 'grommet';

export const ContentWrapper: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <Box
      flex
      pad={{ top: '82px', left: '77px', right: '77px', bottom: '50px' }}
    >
      {children}
    </Box>
  );
};
