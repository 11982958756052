import { FC, useEffect, useMemo, useState } from 'react';
import { BaseLayoutProps } from '@/lib/layouts/types';
import { Box, Heading, TextInput } from 'grommet';
import { RaisedButton } from '@/lib/components/common/buttons';
import { Search, Tree, Update } from 'grommet-icons';
import { Breadcrumbs } from '@/lib/components/app/Breadcrumbs';
import { AssetKindTabs } from './AssetKindTabs';
import { searchVar } from '@/lib/utils/globalVars';
import { debounce } from 'lodash';
import { ContentWrapper } from '@/lib/components/app/ContentWrapper';

export const SearchBar: FC = () => {
  const [value, setValue] = useState<string>('');
  const debouncedReactiveVar = useMemo(() => debounce(searchVar, 500), []);

  useEffect(() => {
    debouncedReactiveVar(value);
  }, [value, debouncedReactiveVar]);

  return (
    <TextInput
      placeholder="Search"
      icon={<Search color="placeholder" size="17px" />}
      value={value}
      onChange={(event) => setValue(event.target.value)}
    />
  );
};

const Header: FC = () => (
  <Box direction="column">
    <Box direction="row" justify="between" align="start">
      {/* My Fleet title */}
      <Box basis="37%">
        <Heading size="3">My Fleet</Heading>
        <SearchBar />

        <AssetKindTabs />
      </Box>
      {/* Manage Assets */}
      <Box basis="50%">
        <Heading size="3">Manage Assets</Heading>
        <Box direction="row" gap="26px">
          <RaisedButton
            label="Synch Data"
            icon={<Update color="brand" />}
            disabled
          />
          <RaisedButton
            label="View Org"
            icon={<Tree color="brand" />}
            disabled
          />
        </Box>
      </Box>
    </Box>
  </Box>
);

export const MyFleetLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <ContentWrapper>
      <Breadcrumbs />
      <Header />
      {children}
    </ContentWrapper>
  );
};
